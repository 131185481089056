import Placeholder from './placeholder/';

class PlaceHolderImage extends HTMLElement {
  constructor() {
    super();
  }

  async connectedCallback() {
    new Placeholder(this, 'img');
  }
}

class PlaceHolderSource extends HTMLElement {
  constructor() {
    super();
  }

  async connectedCallback() {
    new Placeholder(this, 'source');
  }
}

customElements.define('placeholder-image', PlaceHolderImage);
customElements.define('placeholder-source', PlaceHolderSource);
