class Placeholder {
  static ImageGenerators = {};

  #id;
  #component;
  #type;
  #element;
  #settings = {
    height: null,
    width: null,
    size: null,
    color: null,
    metrics: true,
    text: null,
    background: '#dedede',
  };
  #image;
  #applySettings() {
    const internalUseAttributes = [
      'height',
      'width',
      'size',
      'metrics',
      'text',
      'color',
      'background',
    ];

    this.#settings = this.#component.getAttributeNames().reduce(
      (_attributes, name) => {
        if (!internalUseAttributes.includes(name)) return _attributes;
        let value = this.#component.getAttribute(name);
        this.#component.removeAttribute(name);

        if (!isNaN(value)) value = Number(value);
        else if (value.toLowerCase() === 'true') value = true;
        else if (value.toLowerCase() === 'false') value = false;

        return { ..._attributes, [name]: value };
      },
      { ...this.#settings }
    );

    this.#component.getAttributeNames().forEach(name => {
      this.#element.setAttribute(name, this.#component.getAttribute(name));
    });
  }
  #verifySettings() {
    if (!this.#settings.color)
      this.#settings.color = contrastingColor(this.#settings.background);

    if (this.#settings.height && !this.#settings.width)
      this.#settings.width = this.#settings.height;
    else if (this.#settings.width && !this.#settings.height)
      this.#settings.height = this.#settings.width;
    else if (
      !this.#settings.width &&
      !this.#settings.height &&
      this.#settings.size
    )
      this.#settings.height = this.#settings.width = this.#settings.size;
    else if (
      !this.#settings.width &&
      !this.#settings.height &&
      !this.#settings.size
    )
      return;

    delete this.#settings.size;

    try {
      this.#settings.width = parseInt(this.#settings.width);
    } catch (e) {
      throw '<placeholder>: value for width must be an integer';
    }
    try {
      this.#settings.height = parseInt(this.#settings.height);
    } catch (e) {
      throw '<placeholder>: value for height must be an integer';
    }

    if (this.#settings.text) {
      this.#settings.text = this.#settings.text.trim().replace(/\s+/g, ' ');
    }

    Object.entries(this.#settings).forEach(([key, value]) => {
      if (!value) delete this.#settings[key];
    });

    this.#id = `image__${Object.keys(this.#settings)
      .sort()
      .map(k => this.#settings[k])
      .join('-')}`;
  }
  #loadImage_fromStorage() {
    return new Promise((resolve, reject) => {
      try {
        const url = window.URL.createObjectURL(
          dataURItoBlob(localStorage.getItem(this.#id))
        );
        resolve(url);
      } catch (error) {
        reject(error);
      }
    });
  }
  #loadImage_fromCanvas() {
    const { height, width, background, color, metrics, text } = this.#settings;
    return new Promise(async (resolve, reject) => {
      try {
        const canvas = document.createElement('canvas');
        canvas.height = height;
        canvas.width = width;

        if (!document.fonts.check('bold 16px FiraCode')) await loadFont();

        const padding = Math.min(width * 0.075, height * 0.075);
        const ctx = canvas.getContext('2d');

        ctx.fillStyle = background;
        ctx.fillRect(0, 0, width, height);

        if (metrics) {
          let label = {
            size: `${width}x${height}`,
            aspect: getAspectRatio(width, height)
          };
          if(text && text.length > 0) {
            label.text = text
          }
          
          let fontSize = 34;
          let fontSizeSm = Math.floor(fontSize * 0.75);
          let metrics, lineHeight;

          ctx.fillStyle = color;
          ctx.font = `bold ${fontSize}px FiraCode`;
          metrics = ctx.measureText(label.size);

          while (metrics.width > width - padding * 2) {
            fontSize = fontSize - 10;
            fontSizeSm = Math.floor(fontSize * 0.75);
            ctx.font = `bold ${fontSize}px FiraCode`;
            metrics = ctx.measureText(label.size);
          }

          lineHeight =
            (metrics.actualBoundingBoxAscent +
              metrics.actualBoundingBoxDescent) *
            1.5;
          ctx.fillText(label.size, padding, height - padding);

          ctx.font = `bold ${fontSizeSm}px FiraCode`;
          ctx.fillText(label.aspect, padding, height - padding - lineHeight);
          if(label.text) {
            ctx.fillText(label.text, padding, height - padding - lineHeight * 2)
          }
        }

        canvas.toBlob(
          async blob => {
            const url = window.URL.createObjectURL(blob);
            const URIData = await blobToDataURI(blob);
            localStorage.setItem(this.#id, URIData);
            resolve(url);
          },
          'image/webp',
          1
        );
      } catch (error) {
        reject(error);
      }
    });
  }
  #insertIntoPage() {
    this.#element[this.#type === 'img' ? 'src' : 'srcset'] = this.#image;
    this.#element.setAttribute('width', this.#settings.width);
    this.#element.setAttribute('height', this.#settings.height);
    this.#component.insertAdjacentElement('beforebegin', this.#element);
    this.#component.remove();
  }
  async #loadImage() {
    if (!Object.hasOwn(Placeholder.ImageGenerators, this.#id)) {
      Placeholder.ImageGenerators[this.#id] = localStorage.getItem(this.#id)
        ? this.#loadImage_fromStorage()
        : this.#loadImage_fromCanvas();
    }
    this.#image = await Placeholder.ImageGenerators[this.#id];
    this.#insertIntoPage();
    return this.#image;
  }
  constructor(component, type = 'img') {
    if (!component) return;
    this.#component = component;
    this.#type = type;
    this.#element = document.createElement(this.#type);

    this.#applySettings();
    this.#verifySettings();

    return this.#loadImage();
  }
}

export default Placeholder;

function contrastingColor(color) {
  color = color.trim();
  if (color.startsWith('#')) color = color.substr(1);
  if (color.length === 3) {
    color =
      color.charAt(0) +
      color.charAt(0) +
      color.charAt(1) +
      color.charAt(1) +
      color.charAt(2) +
      color.charAt(2);
  } else if (color.length !== 6) {
    throw `<placeholder>: Invalid hex color: '${color}`;
  }
  return luminosity(color) >= 165 ? '#2e2e2e' : '#fefefe';
}

function luminosity(color) {
  const rgb = hexToRGBArray(color);
  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
}

function hexToRGBArray(color) {
  const rgb = [];
  for (let i = 0; i <= 2; i++) {
    rgb[i] = parseInt(color.substr(i * 2, 2), 16);
  }
  return rgb;
}

function getAspectRatio(width, height) {
  const gcd = greatestCommonDenominator(width, height);
  return `${width / gcd}:${height / gcd}`;

  function greatestCommonDenominator(a, b) {
    let temp, m;
    if (b > a) {
      temp = a;
      a = b;
      b = temp;
    }
    while (b != 0) {
      m = a % b;
      a = b;
      b = m;
    }
    return a;
  }
}

function blobToDataURI(blob) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}

function dataURItoBlob(dataURI) {
  if (!dataURI) return false;
  // convert base64 to raw binary data held in a string
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var arrayBuffer = new ArrayBuffer(byteString.length);
  var _ia = new Uint8Array(arrayBuffer);
  for (var i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i);
  }

  var dataView = new DataView(arrayBuffer);
  var blob = new Blob([dataView], { type: mimeString });
  return blob;
}

async function loadFont() {
  const fontURL =
    'https://fonts.gstatic.com/s/firacode/v21/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_A9sJVD7NuzlojwUKQ.woff2';
  const fontFace = new FontFace('FiraCode', `url(${fontURL})`);
  const font = await fontFace.load();
  document.fonts.add(font);
  return font.load();
}
